<template>
  <div class="h100 d-flex flex-column align-center justify-content-center">
    <section v-if="isSucceeded" class="text-center">
      <img src="~@/assets/img/default/payment-check-circle.svg" class="mb-7" />
      <div class="text-success" style="font-weight: 600;">
        Оплата прошла успешно!
      </div>
      <a href="#" @click.prevent="newCertificate" class="ml-black-btn mt-8">
        Купить еще сертификат
      </a>
    </section>
    <section v-else class="text-center">
      <img src="~@/assets/img/default/payment-error.svg" class="mb-7" />
      <div style="font-weight: 600;">
        Оплата не прошла
      </div>
      <a href="#" @click.prevent="newCertificate" class="ml-black-btn mt-8">
        Попробовать еще раз
      </a>
    </section>
    <img
      class="ml-confeti"
      v-if="isSucceeded"
      ref="successconfeti"
      src="@/assets/img/confetti.png"
      alt=""
    />
  </div>
</template>

<script>
const PAYMENT_SUCCEEDED = 'succeeded'
const PAYMENT_PENDING = 'pending'
const PAYMENT_WAITING_FOR_CAPTURE = 'waiting_for_capture'
const PAYMENT_CANCELED = 'canceled'

import { mapActions, mapMutations, mapState } from 'vuex'
// import basketTypes from '@/store/basket/types'
import burgerTypes from '@/store/panelBurger/types'
import yookassaTypes from '@/store/yookassa/types'
// import verificationTypes from '@/store/verificationCode/types'
import Constatns from '@/helpers/constants'

export default {
  computed: {
    ...mapState({
      paymentStatus: state => state?.app?.pagePaymentStatusConfig?.TypeShown,
      isBurgerShow: state => state?.app?.pagePaymentStatusConfig?.BurgerShown
    }),
    // isCanceled() {
    //   return this.payment?.status === PAYMENT_CANCELED
    // },
    isSucceeded() {
      return (
        this.paymentStatus?.toLowerCase() === Constatns?.PAYMENT_STATUS?.SUCCESS
      )
    },
    isNotSucceeded() {
      return (
        this.paymentStatus?.toLowerCase() ===
        Constatns?.PAYMENT_STATUS?.NOT_SUCCESS
      )
    },
    isProccess() {
      return (
        this.paymentStatus?.toLowerCase() ===
        Constatns?.PAYMENT_STATUS?.IN_PROCCESS
      )
    }
    // isWaitingForCapture() {
    //   return this.payment?.status === PAYMENT_WAITING_FOR_CAPTURE
    // },
    // isPending() {
    //   return this.payment?.status === PAYMENT_PENDING
    // }
  },
  methods: {
    ...mapActions('yookassa', [yookassaTypes.BUY_MORE_ACTION]),
    ...mapActions('panelBurger', [burgerTypes.GET_SITE_BURGER_ACTION]),
    // ...mapMutations('basket', [basketTypes.SET_BASKET]),
    // ...mapMutations('verificationCode', [verificationTypes.SET_CONTACTS]),
    newCertificate() {
      this[yookassaTypes.BUY_MORE_ACTION]()
      // this.$store.commit('basket/SET_PREVIEW', null)
      // this.$router.push('/')
    },
    runConfeti() {
      if (this.isSucceeded === true) {
        setTimeout(() => {
          this.$refs.successconfeti.classList.add('ml-confeti-bounce')
          setTimeout(() => {
            this.$refs.successconfeti.remove()
          }, 1000)
        }, 450)
      }
    }
  },
  async mounted() {
    // this[basketTypes.SET_BASKET](null)
    // this[verificationTypes.SET_CONTACTS]({
    // email: null,
    // phone: null,
    // sendingMethod: null
    // })
    // window?.xprops?.onHide() // показываем кнопку close у внешней sidePanel
    setTimeout(() => {
      this.runConfeti()
    }, 450)
    if (this.isBurgerShow) {
      await this[burgerTypes.GET_SITE_BURGER_ACTION]()
      const el = document.getElementById('burger-btn')
      if (el?.style) {
        el.style.opacity = 1
      }
    } else {
    }
  }
}
</script>
